body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Roboto', sans-serif;
}

.ChatContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
    background: rgba(255, 255, 255, 1);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    animation: fadeIn 1s ease-in-out;
}

.ChatContainer::-webkit-scrollbar {
    width: 12px;
}

.ChatContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.ChatContainer::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
}

.ChatContainer::-webkit-scrollbar-thumb:hover {
    background-color: #000000;
}

.ChatContainer::-webkit-scrollbar-corner {
    background: #f1f1f1;
    border-radius: 10px;
}

.ChatContainer {
    scrollbar-width: auto;
    scrollbar-color: #222e45 #ffffff;
}

.Messages {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    animation: fadeIn 1.2s ease-in-out;
}

.Message {
    display: flex;
    align-items: center;
    padding: 6px 20px;
    border-radius: 10px;
    max-width: 100%;
    word-wrap: break-word;
    position: relative;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1), inset 0 0 1px 1px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes popUp {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.02);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.Message img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
}

.Message.user img {
    left: -50px;
}

.Message.bot img {
    right: -50px;
}

.Message.user {
    color: #ffffff;
    background-color: #222e45;
    margin-left: 50px;
    font-size: 16px;
    font-weight: 400;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    animation: popUp 0.2s ease-in-out;
}

.Message.bot {
    color: #ffffff;
    background-color: #222e45;
    margin-right: 40px;
    font-size: 16px;
    font-weight: 400;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.InputContainer {
    width: 100%;
    max-width: 600px;
    background: #222e45;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 10px;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1.4s ease-in-out;
}

.InputBar {
    display: flex;
    width: 100%;
}

.InputBar input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 10px 0 0 10px;
    background: #ffffff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
    outline: none;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}

.InputBar button {
    padding: 10px;
    border: none;
    background-color: #ffb600;
    color: #353239;
    border-radius: 0 10px 10px 0;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}

.InputBar button:hover {
    background-color: #ead9af;
}

.Message a {
    color: #ffb600;
    text-decoration: none;
}

.Message a:hover {
    color: #ead9af;
    text-decoration: underline;
}

.Message a:visited {
    color: #ffb600;
}

.FollowUpButton {
    background-color: #ffb600;
    color: #353239;
    border: none;
    border-radius: 10px;
    padding: 8px 16px 8px 16px;
    margin: 10px 5px 0px 5px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s, background-color 0.2s;
}

.FollowUpButton:hover {
    background-color: #ead9af;
    transform: scale(1.05);
}

.FollowUpButton:active {
    background-color: #d4b28c;
    transform: scale(0.95);
}

.FollowUpButton.clicked {
    background-color: #d4b28c;
    color: #ffffff;
}

.typing-indicator .typing {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 10px 20px 10px;
}

.typing-indicator .typing span {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background-color: #fff;
    border-radius: 50%;
    animation: typing 1s infinite;
}

.typing-indicator .typing span:nth-child(2) {
    animation-delay: 0.2s;
}

.typing-indicator .typing span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes typing {
    0% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-3px);
    }
    50% {
        transform: translateY(0px);
    }
    75% {
        transform: translateY(3px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.Disclaimer {
    color: white;
    margin: 0px 10px 10px 10px;
    text-align: center;
    font-size: 14px;
    animation: fadeIn 1.4s ease-in-out;
}